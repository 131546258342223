import * as React from "react"
import Layout from '../components/layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <section>
      <div className="gc">
        <div className="d-4-10 m-1-13 auto-layout">
          <h1 className="h2">404</h1>
          <p>I'm sorry. The page you were looking for was not found. Please <Link href="/">Go home</Link></p>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
